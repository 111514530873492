import { CimpressDocument, ColorPalette, DesignSurface, ItemClip, MirrorDirection } from "@mcp-artwork/cimdoc-types-v2";
import { DebugInfo, ItemReferenceData, LayoutStatus, OrderableLayoutElement, OrnamentItemInfo, PreviewType } from "../models/Layout";
import { timestamp } from "../utils/time";
import { fetchServerFallback } from "./api";
import { OrderableItemInfo } from "../models/Layout";
import {
  BoundingBox,
  RotatedBoundingBox,
  boundingBoxFromLineItem,
  boundingBoxFromPath,
  computeBoundsFromPosition,
  expandBoundingBox,
} from "../utils/boundingBox";
import { DecorationTechnology } from "../layout/helpers/Technology";
import { Matrix } from "../utils/math/matrix";
import { toRadians } from "../utils/unitHelper";
import { buildTransform } from "../layout/helpers/Transform";
import { log } from "../utils/log";
import { getItemForPanel } from "./utils/getItemForPanel";
import { ClipPath, ImageEntry } from "../layout/Models";
import { FallbackPreviewMetadata, parsePreviewMetadata, parseTextMeasurements } from "./utils/xRenderMetadataHeaderParser";
import { BitmapData, HTMLImageElementData, ImageData, xRenderMetaData } from "../utils/api/image";
import CimDocDefinitionTreeNode from "../utils/CimDocDefinitionTreeNode";
import { MeasurementDataResponse, getMeasurementData } from "../layout/measurements/measurementData";
import { parsePathData } from "../utils/parsePathData";
import { getClipViewboxTransform } from "../layout/helpers/Clip";
import { curveLegacyToCurve } from "../utils/curveLegacy";
import { calculateViewboxTransform } from "../layout/shapes/Curve";

export type FallBackItemOptions = {
  fontRepositoryUrl?: string;
  itemInfo: Exclude<OrderableItemInfo, OrnamentItemInfo>;
  parentBounds: BoundingBox;
  pixelSize: string;
  trackTime: boolean;
  enableLog: boolean;
  decoTech: DecorationTechnology;
  error?: string;
  overprints?: string[];
  definitionTreeNode?: CimDocDefinitionTreeNode;
  previewType: PreviewType;
  colorPalette: ColorPalette | undefined;
  referrer: string;
};

function constructItemReferenceData(data: ImageData): ItemReferenceData | undefined {
  if (data.xItemReferenceUseSlotDimensionsHeader !== undefined || data.xItemReferenceMetadataHeader !== undefined) {
    return {
      metadata: data.xItemReferenceMetadataHeader,
      useSlotDimensions: data.xItemReferenceUseSlotDimensionsHeader,
    };
  }
  return undefined;
}

function constructFallbackImage({
  itemInfo,
  previewType,
  fallback,
  images,
  layoutStatus,
  debugInfo,
  scaleFactor,
}: {
  itemInfo: Exclude<OrderableItemInfo, OrnamentItemInfo>;
  previewType: PreviewType;
  fallback: HTMLImageElementData | BitmapData;
  images: ImageEntry[];
  layoutStatus: LayoutStatus;
  debugInfo?: DebugInfo;
  scaleFactor: number;
}): OrderableLayoutElement {
  const xRenderMetadataHeader = fallback.xRenderMetadataHeader as xRenderMetaData;
  const serverPreviewMetadata: FallbackPreviewMetadata = parsePreviewMetadata(xRenderMetadataHeader);
  const previewBox = { ...serverPreviewMetadata.previewBox } as RotatedBoundingBox;
  let boundingBox: RotatedBoundingBox;
  let mirrorDirection: MirrorDirection | undefined;
  let pathInclusiveBox: RotatedBoundingBox | undefined =
    serverPreviewMetadata.pathInclusiveBox !== undefined ? ({ ...serverPreviewMetadata.pathInclusiveBox } as RotatedBoundingBox) : undefined;
  const textMeasurements = parseTextMeasurements(xRenderMetadataHeader);

  if (itemInfo.itemType === "textArea") {
    boundingBox = computeBoundsFromPosition({ position: itemInfo.item.position });
    if (serverPreviewMetadata.actual === undefined) {
      throw new Error("Expected 'actual' item preview metadata from the server to be defined");
    }
    // For the initial item previews of text, studio will set the height of the item to 0 (and width to 0 if vertical text). Set those values to the
    // textBounds returned by the text engine (server side)
    if (boundingBox.width === 0) {
      boundingBox.width = serverPreviewMetadata.actual.width;
    }
    if (boundingBox.height === 0) {
      boundingBox.height = serverPreviewMetadata.actual.height;
    }

    // preview boxes of text areas that come from the server have a uniform 1.25pt inflation. Undo this here
  } else if (itemInfo.itemType === "shape") {
    const shapeType = itemInfo.item.type;
    if (shapeType === "curve") {
      const curve = curveLegacyToCurve({ curve: itemInfo.item });

      const { scaleX, scaleY, translateX, translateY } = calculateViewboxTransform(curve);

      // Use scale and translate to adjust position using the viewbox
      const [svgPath] = parsePathData({
        pathData: curve.svgPathData ?? "",
        pixelSize: 1,
        svgPathDataUnit: curve.svgPathDataUnit ?? "pt",
        closeBehavior: curve.closeBehavior,
        scaleX,
        scaleY,
        translateX,
        translateY,
      });

      boundingBox = boundingBoxFromPath({ path: svgPath });
    } else if (shapeType === "ellipse" || shapeType === "rectangle") {
      boundingBox = computeBoundsFromPosition({ position: itemInfo.item.position });
    } else {
      boundingBox = boundingBoxFromLineItem({ line: itemInfo.item });
    }
  } else if (itemInfo.itemType === "subpanel") {
    boundingBox = { ...previewBox };
  } else if (itemInfo.itemType === "itemReference") {
    boundingBox = computeBoundsFromPosition({ position: itemInfo.item.position });
  } else if (itemInfo.itemType === "image") {
    boundingBox = computeBoundsFromPosition({ position: itemInfo.item.position });
    mirrorDirection = itemInfo.item.mirrorDirection;
  } else {
    throw new Error("Fallback not supported");
  }

  if (!(itemInfo.itemType === "shape" && itemInfo.item.type === "line")) {
    previewBox.left += boundingBox.left;
    previewBox.top += boundingBox.top;
  }

  const transform = buildTransform({
    bounds: boundingBox,
    scale: itemInfo.item.scale,
    rotationAngle: itemInfo.item.rotationAngle,
    mirrorDirection: mirrorDirection,
    itemTransforms: itemInfo.item.transforms,
    matrixTransform: itemInfo.item.transform,
  });

  let tightBounds = { ...previewBox };

  // previewBoxes of text areas that come back from the server are inflated by 1.25pt on all sides. Undo this temporarily
  // since the getMeasurementData() requires tight bounds.
  if (itemInfo.itemType === "textArea") {
    tightBounds = expandBoundingBox({ boundingBox: tightBounds, amount: -1.25 * 0.352778 });
  }

  const measurementData = getMeasurementData({
    itemType: itemInfo.itemType,
    boundingBox,
    tightBounds,
    transform,
    scaleTransform: itemInfo.item.scale,
  });

  // Redo the inflation. Also apply it to the layoutBox since we don't want the item preview canvas to be too tightly bound.
  if (itemInfo.itemType === "textArea") {
    measurementData.measurementData.previewBox = expandBoundingBox({ boundingBox: measurementData.measurementData.previewBox, amount: 1.25 * 0.352778 });
    measurementData.measurementData.layoutBox = expandBoundingBox({ boundingBox: measurementData.measurementData.layoutBox, amount: 1.25 * 0.352778 });
  }

  if (pathInclusiveBox) {
    // snap box of text along a path is the path inclusive box, without position translation
    if (textMeasurements) {
      textMeasurements.snapBox = { ...pathInclusiveBox };
    }
    // Since we zero out the position before sending the item to the server, the server's pathInclusiveBox does not have the
    // original position applied. Apply it here
    pathInclusiveBox.left += boundingBox.left;
    pathInclusiveBox.top += boundingBox.top;

    const pathInclusiveBoxMeasurements = getMeasurementData({
      boundingBox,
      tightBounds: pathInclusiveBox,
      itemType: itemInfo.itemType,
      transform,
    });

    pathInclusiveBox = pathInclusiveBoxMeasurements.measurementData.previewBox;
  }

  let clip: ClipPath | undefined;
  let clipBounds: BoundingBox | undefined;

  // TODO: fix
  if (itemInfo.item.clipping) {
    const modifiedClipping = getModifiedClipping(itemInfo.item.clipping, measurementData as MeasurementDataResponse, images[0], previewType);
    clip = modifiedClipping.clip;
    clipBounds = modifiedClipping.clip?.boundingBox;
  }

  return {
    depth: itemInfo.depth,
    value: {
      id: itemInfo.item.id,
      measurementData: {
        boundingBox: measurementData.measurementData.boundingBox,
        previewBox: measurementData.measurementData.previewBox,
        // Since this is a drawImage operation, the layoutBox is irrelevant. Instead the boundingBox of the renderingOperation is used
        layoutBox: clipBounds ?? measurementData.measurementData.layoutBox,
        pathInclusiveBox,
        textMeasurements,
      },
      renderingOperation: {
        type: "drawImage",
        images,
        clip: clip,
        boundingBox: previewBox,
        transform,
        opacityMultiplier: 1,
        SSRDeferredTransformData: {
          scale: measurementData.measurementData.scale,
          itemPreviewScaleFactor: scaleFactor,
        },
      },
      itemReferenceData: constructItemReferenceData(fallback),
      status: layoutStatus,
      debugInfo: debugInfo,
    },
  };
}

export async function fallbackItem(options: FallBackItemOptions): Promise<OrderableLayoutElement> {
  const { decoTech, enableLog, fontRepositoryUrl, itemInfo, previewType, pixelSize, trackTime, error, referrer } = options;
  const start = timestamp(trackTime);
  log({ message: `falling back to server for item ${itemInfo.itemType} ${itemInfo.item.id}`, enabled: enableLog, objects: { itemInfo, error } });

  const { itemToAdd, scaleFactor } = await getItemForPanel({
    itemInfo,
    previewType,
  });

  // Create temp document with position and rotation fix
  const panelWithOnlyItem: DesignSurface = {
    id: "preview-0",
    name: "preview-0",
    width: "1mm", // not used for item preview on SSR
    height: "1mm", // not used for item preview on SSR
    decorationTechnology: decoTech,
    ...itemToAdd,
  };

  const document: CimpressDocument = {
    version: "2.0",
    fontRepositoryUrl,
    document: {
      definitions: options.definitionTreeNode?.definition,
      colorPalette: options.colorPalette,
      panels: [panelWithOnlyItem],
    },
  };

  log({
    message: `falling back to server for item ${itemInfo.itemType} ${itemInfo.item.id} document created`,
    enabled: enableLog,
    objects: { document, pixelSize },
  });

  const allOverprints: Array<string | undefined> = [undefined];
  (options.overprints ?? []).forEach((overprint) => allOverprints.push(overprint));

  const fallbacks = await Promise.all(
    allOverprints.map((overprint) => fetchServerFallback({ document, pixelSize, instructionType: "item", overprint, referrer, scaleFactor })),
  );
  const fallback = fallbacks[0];

  if (fallback.xRenderMetadataHeader) {
    log({
      message: `falling back to server for item ${itemInfo.itemType} ${itemInfo.item.id} received xRenderMetdataHeader`,
      enabled: enableLog,
      objects: { itemInfo, xRenderMetdataHeader: fallback.xRenderMetadataHeader },
    });
  }

  // Get measurement data with original position and rotationangle.
  if (fallback.xRenderMetadataHeader === undefined) {
    throw Error("Missing x-render-metadata header");
  }

  const images: ImageEntry[] = allOverprints.map((overprint, i) => ({
    overprint,
    images: [fallbacks[i].image],
  }));

  const debugInfo: DebugInfo | undefined = start ? { timers: { total: timestamp(true) - start } } : undefined;

  return constructFallbackImage({
    itemInfo,
    previewType,
    images,
    fallback,
    layoutStatus: { mode: "server", error },
    debugInfo,
    scaleFactor,
  });
}

/**
 * Since the fallback item that is sent to the server does not contain the clipping, we will modify the clip.
 * @param clip
 * @param measurementData
 * @param imageEntry
 * @param previewType
 * @returns an object with potentially baseClipBounds and the modified clip (undefined if none exists). The baseClipBounds
 *          is only returned for origin = item clips since the bounding box of the clip (excluding any item transforms such as rotation)
 *          is required as the preview box of getMeasurementData()
 */
function getModifiedClipping(
  clip: ItemClip | undefined,
  measurementData: MeasurementDataResponse,
  imageEntry: ImageEntry,
  previewType: PreviewType,
): { baseClipBounds?: BoundingBox; clip: ClipPath | undefined } {
  if (clip) {
    const spec = clip.specification;
    const fallbackImage = imageEntry.images[0];

    if (spec.type === "textArea") {
      return { clip: undefined };
    }

    // This scale transform is to scale the clip according to how the fallback image is drawn at its actual dimensions and not the dimensions
    // in millimeters. See the paintSSRImage() function in neon for details.
    const scale = Matrix.scale(
      fallbackImage.width / measurementData.measurementData.boundingBox.width,
      fallbackImage.height / measurementData.measurementData.boundingBox.height,
    );
    const [svgPath, path] = parsePathData({ pathData: spec.data, pixelSize: 1, svgPathDataUnit: spec.unit ?? "mm", closeBehavior: undefined });
    const boundingBox = measurementData.measurementData.boundingBox;

    // if a viewBox is provided, the origin is always assumed to be "item"
    // the logic here is very similar to where origin = "item" since item transforms also apply to the clip
    if (clip.viewBox !== undefined) {
      if (previewType === "document") {
        let transform = measurementData.itemPreviewTransform;
        transform = Matrix.multiply(transform, getClipViewboxTransform(boundingBox, clip.viewBox));
        const baseTransform = transform.copy();
        transform = Matrix.multiply(transform, Matrix.rotateAboutCenter(toRadians(boundingBox.rotation ?? 0), boundingBox));

        return {
          clip: {
            path,
            transform,
            boundingBox: boundingBoxFromPath({ path: svgPath, transform }),
            isRelativeToItem: true,
            usesViewbox: true,
          },
          baseClipBounds: boundingBoxFromPath({ path: svgPath, transform: baseTransform }),
        };
      } else {
        let transform = measurementData.itemPreviewTransform;
        transform = Matrix.multiply(transform, getClipViewboxTransform(boundingBox, clip.viewBox));

        return {
          clip: {
            path,
            transform: Matrix.multiply(transform, scale),
            boundingBox: boundingBoxFromPath({ path: svgPath, transform }),
            isRelativeToItem: true,
            usesViewbox: true,
          },
          baseClipBounds: boundingBoxFromPath({ path: svgPath, transform }),
        };
      }
    }

    if (spec.origin === "panel") {
      // do nothing
      if (previewType === "document") {
        return {
          clip: {
            path,
            transform: Matrix.identity(),
            boundingBox: boundingBoxFromPath({ path: svgPath }),
            isRelativeToItem: false,
            usesViewbox: false,
          },
        };
      }

      // assume that the previewType is "item". Need to translate that to the origin along with the item
      const translateToOrigin = Matrix.translate(-measurementData.itemPreviewTransform.x, -measurementData.itemPreviewTransform.y);
      return {
        clip: {
          path,
          transform: Matrix.multiply(translateToOrigin, scale),
          boundingBox: boundingBoxFromPath({ path: svgPath, transform: translateToOrigin }),
          isRelativeToItem: false,
          usesViewbox: false,
        },
      };
    }
    // origin = "item"
    else {
      // If document preview, need to place the item preview to its absolute position on the panel. To do this, include the
      // rotation of the previewBox as part of the clip transform.
      if (previewType === "document") {
        let transform = measurementData.itemPreviewTransform;
        transform = Matrix.multiply(transform, Matrix.translate(boundingBox.left, boundingBox.top));
        const baseTransform = transform.copy();
        transform = Matrix.multiply(transform, Matrix.rotateAboutCenter(toRadians(boundingBox.rotation ?? 0), boundingBox));

        return {
          clip: {
            path,
            transform: transform,
            boundingBox: boundingBoxFromPath({ path: svgPath, transform }),
            isRelativeToItem: true,
            usesViewbox: false,
          },
          baseClipBounds: boundingBoxFromPath({ path: svgPath, transform: baseTransform }),
        };
      }

      // assume previewType = "item". Need to translate that to the origin along with the item, and unlike the case for document previews,
      // the clip must not have the rotation of the previewBox as part of the clip transform.
      let transform = measurementData.itemPreviewTransform;
      transform = Matrix.multiply(transform, Matrix.translate(boundingBox.left, boundingBox.top));

      return {
        clip: {
          path,
          transform: Matrix.multiply(transform, scale),
          boundingBox: boundingBoxFromPath({ path: svgPath, transform }),
          isRelativeToItem: true,
          usesViewbox: false,
        },
        baseClipBounds: boundingBoxFromPath({ path: svgPath, transform }),
      };
    }
  }

  return { clip: undefined };
}
