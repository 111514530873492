import { DesignSurface, HexagonTileDefinition } from "@mcp-artwork/cimdoc-types-v2";
import { TileAugmenter } from "./TileAugmenter";
import { HexagonCalculator } from "./HexagonCalculator";
import { parseMM } from "../../unitHelper";
import { HexagonSvgPathCreator } from "./SvgPathCreator";
import { BoundingBox } from "../../boundingBox";

export class HexagonTileAugmenter extends TileAugmenter {
  private tileOffset: number;
  private tileWidth: number;
  private tileHeight: number;

  private hexWidth: number;
  private hexHeight: number;

  // at least small space to make pattern visible
  private defaultTileOffset = 1;

  // regular hexagon
  private static SquareRoot3 = Math.sqrt(3);
  private side: number;

  constructor(tile: HexagonTileDefinition, definitionPanel: DesignSurface) {
    super(tile, definitionPanel);

    this.tileOffset = tile.offset ? parseMM(tile.offset) : this.defaultTileOffset;
    this.side = this.calculateSide();

    this.hexWidth = this.getHexWidth(this.side);
    this.hexHeight = this.getHexHeight(this.side);

    this.tileWidth = this.hexWidth + this.side + 2 * this.tileOffset;
    this.tileHeight = this.hexHeight + this.tileOffset;
  }

  private calculateSide(): number {
    const widthSide = this.defpanelWidth / 2;
    const heightSide = this.defpanelHeight / HexagonTileAugmenter.SquareRoot3;
    return widthSide < heightSide ? widthSide : heightSide;
  }

  private getHexHeight(side: number): number {
    // h = 2 a cos30 = sqrt(3) a
    return side * HexagonTileAugmenter.SquareRoot3;
  }

  private getHexWidth(side: number): number {
    return 2 * side;
  }

  /// Regular Hexagon Tile without seam will be created as follows
  protected override augmentTilePatterns() {
    const hexagon = new HexagonCalculator(this.side, this.tileOffset);

    hexagon.getCenterPoints().forEach((hexCenter) => {
      const hexBound = this.createHexagonBoundBox(hexCenter.x, hexCenter.y, this.hexWidth, this.hexHeight);
      this.augmentPattern(hexBound, 1, undefined, new HexagonSvgPathCreator(hexCenter.x, hexCenter.y, this.hexWidth, this.hexHeight));
    });
  }

  private createHexagonBoundBox(cX: number, cY: number, width: number, height: number): BoundingBox {
    const boundingBox: BoundingBox = {
      left: cX - width / 2,
      top: cY - height / 2,
      width: width,
      height: height,
    };

    return boundingBox;
  }

  protected override getTileWidth(): number {
    return this.tileWidth;
  }

  protected override getTileHeight(): number {
    return this.tileHeight;
  }
}
