const DEFAULT_SHERBERT_BASE_URL = "https://api.sherbert.cimpress.io/v2/assets";

export type SherbertFile = {
  id: string;
  signature?: string;
};

type AccessToken = {
  accessToken: string;
};

type PresignAssetArgs = {
  id: string;
} & AccessToken;

export async function presignSherbertAsset({ id, accessToken }: PresignAssetArgs): Promise<SherbertFile> {
  const response = await fetch(`${DEFAULT_SHERBERT_BASE_URL}/${id}:presign`, {
    method: "POST",
    headers: new Headers({ authorization: `Bearer ${accessToken}` }),
  });
  const json = await response.json();

  return { id, signature: json.signature };
}

export async function listSherbertAssets({ accessToken }: AccessToken): Promise<SherbertFile[]> {
  const response = await fetch(DEFAULT_SHERBERT_BASE_URL, { headers: new Headers({ authorization: `Bearer ${accessToken}` }) });
  const json = (await response.json()) as { _embedded?: { item: { id: string }[] } };
  // Return only ids
  const ids = (json._embedded?.item ?? []).map((item: { id: string }) => ({ id: item.id }));
  return ids;
}

type CreateSherbertAssetArgs = {
  file: File;
  onProgress: (percentComplete: number) => void;
} & AccessToken;

export async function createSherbertAsset({ accessToken, file, onProgress }: CreateSherbertAssetArgs): Promise<SherbertFile> {
  return new Promise((resolve, reject) => {
    const body = new FormData();
    body.set("file", file);
    body.set("expires", "P1D");
    body.set("properties", JSON.stringify({ source: "fusion-demo-page" }));

    // Use XMLHttpRequest instead of fetch for onProgress support
    const xhr = new XMLHttpRequest();
    xhr.open("POST", DEFAULT_SHERBERT_BASE_URL, true);
    xhr.upload.addEventListener("progress", (event: ProgressEvent) => onProgress((event.loaded * 100) / event.total));
    xhr.setRequestHeader("authorization", `Bearer ${accessToken}`);
    xhr.setRequestHeader("prefer", "respond-async,wait=30");

    xhr.onload = () => {
      const response = JSON.parse(xhr.response);
      resolve({ id: response.id });
    };
    xhr.onabort = () => reject("aborted");
    xhr.onerror = () => reject(xhr.statusText);
    xhr.ontimeout = () => reject("timeout");

    xhr.send(body);
  });
}
