import {
  TextAlignment,
  ParagraphAlignment,
  TextOrientation,
  BlockProgressionDirection,
  TextArea as rTextArea,
  FontFlavor,
  FontStyle,
  CalculationRequest,
  TextBlock,
  Resizing,
  CalculationRequestCollection,
} from "@mcp-artwork/rtext";
import {
  Casing,
  ListContent,
  ResizingOptions,
  Stroke,
  TextArea,
  TextAreaWithContent,
  TextAreaWithFields,
  TextDecoration,
  TextField,
  TextFieldContent,
  TextList,
} from "@mcp-artwork/cimdoc-types-v2";
import { parseMM } from "../../utils/unitHelper";
import { TextOptions } from "../../models/Layout";
import { createChildFontInformation, getListOrdinal } from "./ListOrdinals";
import { PiecewisePath } from "./textOnPath/piecewisePath";
import { cloneDeep } from "lodash";

export const NEW_LINE = "\n";
export const CARRIAGE_RETURN = "\r";
export const BASE_INDENT_MULTIPLIER = 1.5;

export type InterpretedTextContent = {
  listOrdinal: boolean;
  isNewParagraphTextBlock: boolean;
  textField: TextField & { type?: "inline" | "list" };
  textBlock: TextBlock;
};

export type FontInformation = {
  family?: string;
  style?: string;
  size?: string;
  color?: string;
  stroke?: Stroke;
  decorations?: TextDecoration[];
};

export function interpretTextArea(textArea: TextArea, flavor: FontFlavor): InterpretedTextContent[] {
  const interpretedContent: InterpretedTextContent[] = [];

  let previousType: "list" | "inline" | undefined = undefined;
  const normalizedTextArea = normalizeTextArea(textArea);

  for (const textContent of normalizedTextArea.content) {
    const parentFont: FontInformation = {
      family: textContent.fontFamily,
      style: textContent.fontStyle,
      size: textContent.fontSize,
      color: textContent.color,
      stroke: textContent.stroke,
      decorations: textContent.decorations,
    };

    const contentType = (textContent as { type?: "list" }).type ?? "inline";

    if (previousType !== undefined && interpretedContent.length > 0) {
      const previousInterpretedContent: InterpretedTextContent = interpretedContent[interpretedContent.length - 1];

      // There must be a new line between lists and inline content, or pairs of lists
      if (previousType === "inline" && contentType === "list") {
        previousInterpretedContent.textBlock.content += NEW_LINE;
      } else if (previousType === "list" && contentType === "inline") {
        previousInterpretedContent.textBlock.content += NEW_LINE;
      } else if (previousType === "list" && contentType === "list") {
        previousInterpretedContent.textBlock.content += NEW_LINE;
      }
    }

    switch (contentType) {
      case "inline": {
        const textField = textContent as TextField;
        const casedTextField: TextField = {
          ...textField,
          content: applyCasing(textField.content, textField.casing),
        };

        interpretedContent.push({
          listOrdinal: false,
          isNewParagraphTextBlock: false,
          textField: {
            ...casedTextField,
            type: "inline",
          },
          textBlock: buildTextBlockRequest(casedTextField, flavor, textArea.textPath !== undefined),
        });

        previousType = "inline";
        break;
      }
      case "list": {
        if (textContent.content === undefined) {
          throw Error("Lists must contain text content!");
        }

        if (typeof textContent.content === "string") {
          throw Error("Lists cannot contain string content, only list content is supported!");
        }

        if (textArea.textPath === undefined) {
          interpretedContent.push(...interpretList(textContent.content, parentFont, flavor));
        } else {
          interpretedContent.push(...interpretListForTextPath(textContent.content, parentFont, flavor));
        }

        previousType = "list";
        break;
      }
      default:
        // eslint-disable-next-line
        // @ts-ignore
        throw Error(`Text content has invalid type: ${textContent.type}!`);
    }
  }

  return interpretedContent;
}

export function buildInteractiveRequest(
  textArea: TextArea,
  pieceWisePath: PiecewisePath | undefined,
  textBlocks: TextBlock[],
  fontFlavor: FontFlavor,
  fontRepositoryUrl: string,
  textOptions?: TextOptions,
): CalculationRequestCollection {
  const { horizontalAlignment, verticalAlignment, blockFlowDirection, resizingOptions } = textArea;
  let { textOrientation, blockProgressionDirection } = translateFlowDirection(blockFlowDirection); // eslint-disable-line

  let engineWidth: number | undefined;
  let engineHeight: number | undefined;
  let paragraphAlignment: ParagraphAlignment = "near";
  let textAlignment: TextAlignment = translateTextAlignment(horizontalAlignment);
  const resizing: Resizing | null = translateResizeOptions(resizingOptions, textArea);
  let preventWrapping = resizingOptions?.wrapping === "prevent";

  if (pieceWisePath !== undefined) {
    if (textOrientation !== "horizontal") {
      throw new Error("Vertical text alignment not supported for text along a path!");
    }

    engineWidth = pieceWisePath.length;
    blockProgressionDirection = "forward";
    // werapping is disabled for text along a path
    preventWrapping = true;

    if (textArea.textPath?.startOffset !== undefined) {
      textAlignment = "leading";
      engineWidth = pieceWisePath.length * (1.0 - textArea.textPath.startOffset);
    }
  } else {
    engineWidth = parseTextDimension(textArea.position.width);
    engineHeight = parseTextDimension(textArea.position.height);
    paragraphAlignment = translateParagraphAlignment(verticalAlignment);
  }

  const rTextArea: rTextArea = {
    width: engineWidth,
    height: engineHeight,
    textAlignment: textAlignment,
    paragraphAlignment: paragraphAlignment,
    textOrientation,
    blockProgressionDirection,
    fontRepositoryUrl: fontRepositoryUrl,
    fontFlavor,
    textBlocks,
    resizing: resizing,
    textJustification: textArea.textJustification ?? "none",
    textWrapping: preventWrapping ? "none" : "normal",
  };

  const request: CalculationRequest = {
    textArea: rTextArea,
    requestGlyphRuns: true,
    requestMeasurements: true,
    requestOutlines: "true",
    requestCaretPositions: true,
    requestLineInfo: true,
    requestGlyphBounds: true,
    requestTextBoundaries: textOptions?.requestTextBoundaries,
  };

  return {
    version: 1,
    requests: [request],
    requestFontUrls: false,
    requestFontMetrics: true,
  };
}

export function translateResizeOptions(options: ResizingOptions | undefined, textArea: TextArea | undefined): Resizing | null {
  if (options === undefined) {
    return null;
  }

  const { minFontSize, maxFontSize } = getMinAndMaxFontSizes(textArea);

  let minimumFontSize = 0;
  let maximumFontSize: number | null = null;

  if (options.fit === "expandToFill") {
    minimumFontSize = minFontSize === Number.MAX_VALUE ? 0 : minFontSize;
  } else if (options.fit === "shrinkToFit") {
    maximumFontSize = maxFontSize === Number.MIN_VALUE ? null : maxFontSize;
  }

  const resizing: Resizing = {
    boundsType: options.bounds?.toLowerCase() === "blackbox" ? "blackBox" : "text",
    minimumFontSize,
    maximumFontSize,
  };

  const ruleMinFontSize = options.rules?.minimumFontSize;
  const ruleMaxFontSize = options.rules?.maximumFontSize;

  if (ruleMinFontSize !== undefined || ruleMaxFontSize !== undefined) {
    resizing.minimumFontSize = ruleMinFontSize !== undefined ? parseMM(ruleMinFontSize ?? "0pt") : 0;
    resizing.maximumFontSize = ruleMaxFontSize !== undefined ? parseMM(ruleMaxFontSize ?? "0pt") : null;
  }

  return resizing;
}

export function translateFontStyle(style: string | undefined): FontStyle {
  if (style === undefined) {
    return "normal";
  }

  const normalizedStyle = style.toLowerCase();
  const italic = normalizedStyle.includes("italic");
  const bold = normalizedStyle.includes("bold");
  const strikeout = normalizedStyle.includes("strikeout");
  const underline = normalizedStyle.includes("underline");
  const normal = normalizedStyle.includes("normal");

  if (italic && bold) {
    return "bold,italic";
  }
  if (bold) {
    return "bold";
  }
  if (italic) {
    return "italic";
  }

  // Default to normal if only a strike out underline is defined
  if (strikeout || underline) {
    return "normal";
  }

  if (normal) {
    return "normal";
  }

  throw Error(`Could not parse font style: ${style}!`);
}

export function translateTextAlignment(alignment: string | undefined): TextAlignment {
  switch (alignment?.toLowerCase()) {
    case "left":
      return "leading";
    case "right":
      return "trailing";
    case "center":
      return "center";
    default:
      return "center";
  }
}

export function translateParagraphAlignment(alignment: string | undefined): ParagraphAlignment {
  switch (alignment?.toLowerCase()) {
    case "top":
      return "near";
    case "middle":
      return "center";
    case "bottom":
      return "far";
    default:
      return "center";
  }
}

export function translateFlowDirection(direction: string | undefined): {
  textOrientation: TextOrientation;
  blockProgressionDirection: BlockProgressionDirection;
} {
  switch (direction?.toLocaleLowerCase()) {
    case "horizontal-tb":
      return { textOrientation: "horizontal", blockProgressionDirection: "forward" };
    case "vertical-lr":
      return { textOrientation: "vertical", blockProgressionDirection: "forward" };
    case "vertical-rl":
      return { textOrientation: "vertical", blockProgressionDirection: "reverse" };
    default:
      return { textOrientation: "horizontal", blockProgressionDirection: "forward" };
  }
}

export function containsUrl(input: string | undefined): boolean {
  if (input && input.startsWith("http")) {
    return true;
  }

  return false;
}

function applyCasing(input: string, casing?: Casing): string {
  // TODO update cimdoctypes enum for casing to be camel case
  switch (casing?.toLowerCase()) {
    default:
    case "default":
      return input;
    case "lowercase":
      return input.toLowerCase();
    case "uppercase":
      return input.toUpperCase();
  }
}

// Parses a dimension of the text area. Zero should be treated as undefined for the text engine.
function parseTextDimension(dimension: string): number | undefined {
  return parseMM(dimension) || undefined;
}

function normalizeTextArea(textArea: TextArea): TextAreaWithContent {
  const textAreaWithContent: TextAreaWithContent = textArea as TextAreaWithContent;

  // If the text area doesn't have content, check if its a text field
  if (!textAreaWithContent.content) {
    const textAreaWithFields: TextAreaWithFields = textArea as TextAreaWithFields;

    if (!textAreaWithFields.textFields) {
      throw new Error("A text area must have content or fields!");
    }

    return {
      id: textArea.id,
      position: textArea.position,
      horizontalAlignment: textArea.horizontalAlignment,
      verticalAlignment: textArea.verticalAlignment,
      curveAlignment: textArea.curveAlignment,
      blockFlowDirection: textArea.blockFlowDirection,
      textOrientation: textArea.textOrientation,
      rotationAngle: textArea.rotationAngle,
      viewBox: textArea.viewBox,
      curves: textArea.curves,
      glyphRunOverrides: textArea.glyphRunOverrides,
      resizingOptions: textArea.resizingOptions,
      metadata: textArea.metadata,
      content: textAreaWithFields.textFields,
    };
  }

  return textAreaWithContent;
}

function initializeOrdinalsPerDepth(listContents: ListContent[]): number[] {
  let maxDepth = 0;

  for (const listContent of listContents) {
    if (listContent.depth === undefined) {
      maxDepth = Math.max(maxDepth, 1);
    } else {
      maxDepth = Math.max(maxDepth, listContent.depth);
    }
  }

  const ordinalsPerDepth: number[] = [];

  for (let i = 0; i < maxDepth; i++) {
    ordinalsPerDepth.push(0);
  }

  return ordinalsPerDepth;
}

function buildTextBlockRequest(textField: TextField, flavor: FontFlavor, path: boolean, indent?: string): TextBlock {
  const { fontFamily, fontSize, lineHeight, letterspacing } = textField;

  const openTypeFeatures: Record<string, number> = {};
  if (letterspacing !== undefined || path) {
    openTypeFeatures.liga = 0;
  }

  const isUrl: boolean = containsUrl(fontFamily);

  const request: TextBlock = {
    fontSize: parseMM(fontSize),
    content: Array.isArray(textField.content) ? textField.content.join("") : textField.content,
    whitespaceStripping: "none",
    lineHeight,
    letterSpacing: letterspacing,
    openTypeFeatures,
    fontReferences: [
      {
        url: isUrl ? fontFamily : undefined,
        fontFamily: !isUrl ? fontFamily : undefined,
        fontStyle: !isUrl ? translateFontStyle(textField.fontStyle) : undefined,
        fontFlavor: flavor,
      },
    ],
  };

  // Add an optional indent
  if (indent !== undefined) {
    request.paragraphCharacteristics = {
      indent: parseMM(indent),
      paragraphIndent: 0.0,
    };
  }

  return request;
}

function getMinAndMaxFontSizes(textArea: TextArea | undefined): { minFontSize: number; maxFontSize: number } {
  let minFontSize = Number.MAX_VALUE;
  let maxFontSize = Number.MIN_VALUE;

  if (textArea === undefined) {
    return { minFontSize, maxFontSize };
  }

  const textAreaWithContent = textArea as TextAreaWithContent;

  if (textAreaWithContent.content) {
    for (const content of textAreaWithContent.content) {
      const { minFontSize: min, maxFontSize: max } = getContentMinAndMaxFontSizes(content);

      minFontSize = Math.min(minFontSize, min);
      maxFontSize = Math.max(maxFontSize, max);
    }
  } else {
    const textAreaWithFields = textArea as TextAreaWithFields;
    for (const textField of textAreaWithFields.textFields ?? []) {
      minFontSize = Math.min(minFontSize, parseMM(textField.fontSize));
      maxFontSize = Math.max(maxFontSize, parseMM(textField.fontSize));
    }
  }

  return { minFontSize, maxFontSize };
}

function getContentMinAndMaxFontSizes(content: TextFieldContent | TextList): { minFontSize: number; maxFontSize: number } {
  let minFontSize = Number.MAX_VALUE;
  let maxFontSize = Number.MIN_VALUE;

  if (!content.type || content.type === "inline") {
    minFontSize = Math.min(minFontSize, parseMM(content.fontSize ?? "0pt"));
    maxFontSize = Math.max(maxFontSize, parseMM(content.fontSize ?? "0pt"));
  } else if (content.type === "list") {
    for (const listContent of content.content) {
      minFontSize = Math.min(minFontSize, parseMM(listContent.fontSize ?? "0pt"));
      maxFontSize = Math.max(maxFontSize, parseMM(listContent.fontSize ?? "0pt"));

      for (const textFieldContent of listContent.content) {
        const { minFontSize: tfMin, maxFontSize: tfMax } = getContentMinAndMaxFontSizes(textFieldContent);

        minFontSize = Math.min(minFontSize, tfMin);
        maxFontSize = Math.max(maxFontSize, tfMax);
      }
    }
  }

  return { minFontSize, maxFontSize };
}

function interpretList(listContents: ListContent[], parentFont: FontInformation, flavor: FontFlavor): InterpretedTextContent[] {
  const interpretedContent: InterpretedTextContent[] = [];
  const ordinalsPerDepth: number[] = initializeOrdinalsPerDepth(listContents);

  for (let contentIndex = 0; contentIndex < listContents.length; contentIndex++) {
    const listItem: ListContent = listContents[contentIndex];

    if (listItem.content === undefined || listItem.content.length === 0) {
      throw Error("List items must have at least one text field!");
    }

    const currentDepth = listItem.depth || 1;

    // Get the ordinal character and white space content
    const listContent: InterpretedTextContent[] = getListOrdinal(listItem, parentFont, ordinalsPerDepth, currentDepth);
    const fontSizeString = listItem.fontSize ?? parentFont.size;
    if (fontSizeString === undefined) {
      throw Error("Cannot determine list font");
    }
    const indentFontSize: number = parseMM(fontSizeString);
    // Add user content on the list
    for (let itemIndex = 0; itemIndex < listItem.content.length; itemIndex++) {
      const itemTextField: TextFieldContent = listItem.content[itemIndex];

      let offset: string | undefined = undefined;

      let content = itemTextField.content;
      const contents = content.split(/\r\n|\r|\n/);
      for (let i = 0; i < contents.length; i++) {
        let textBlockContent = contents[i];
        const nextCharacter = content[textBlockContent.length];
        const nextToNextCharacter = content[textBlockContent.length + 1];
        if (nextCharacter === CARRIAGE_RETURN && nextToNextCharacter === NEW_LINE) {
          textBlockContent += CARRIAGE_RETURN + NEW_LINE;
        } else if (nextCharacter === CARRIAGE_RETURN) {
          textBlockContent += CARRIAGE_RETURN;
        } else if (nextCharacter === NEW_LINE) {
          textBlockContent += NEW_LINE;
        }
        if (textBlockContent === "" && itemIndex < listItem.content.length - 1) {
          continue;
        }
        content = content.slice(textBlockContent.length);
        if (
          i > 0 ||
          (i === 0 &&
            listItem.content[itemIndex - 1] &&
            (listItem.content[itemIndex - 1].content.endsWith(NEW_LINE) || listItem.content[itemIndex - 1].content.endsWith(CARRIAGE_RETURN)))
        ) {
          offset = `${indentFontSize * BASE_INDENT_MULTIPLIER * currentDepth} mm`;
        } else offset = undefined;

        const childFontInfo = createChildFontInformation(listItem, parentFont);
        const fontFamily = itemTextField.fontFamily ?? childFontInfo.family;
        const fontStyle = itemTextField.fontStyle ?? childFontInfo.style;
        const fontSize = itemTextField.fontSize ?? childFontInfo.size;
        const color = itemTextField.color ?? childFontInfo.color;
        const stroke = itemTextField.stroke ?? childFontInfo.stroke;
        const decorations = itemTextField.decorations ?? childFontInfo.decorations;

        if (!fontFamily || !fontStyle || !fontSize || !color) {
          throw Error("Cannot determine list font style and color");
        }
        // The text field can override the parent fonts properties
        const textField: TextField = {
          id: "list item",
          content: applyCasing(textBlockContent, itemTextField.casing),
          fontFamily,
          fontStyle,
          fontSize,
          color,
          stroke,
          decorations,
        };

        const textBlock = buildTextBlockRequest(textField, flavor, false, offset);

        listContent.push({
          listOrdinal: false,
          isNewParagraphTextBlock: i > 0,
          textField,
          textBlock,
        });
      }
    }

    // Ensure that list content ends with a new line
    if (contentIndex !== listContents.length - 1 && listContent.length > 0) {
      const lastContent: InterpretedTextContent = listContent[listContent.length - 1];

      lastContent.textField.content += NEW_LINE;
      lastContent.textBlock.content += NEW_LINE;
    }

    interpretedContent.push(...listContent);
  }

  return interpretedContent;
}

/**
 * Custom list interpretation for text along a path with text lists. The main difference between this and interpretList() is that all
 * new lines, whether explicit in content or from implicit list behavior are converted to single spaces. The instructions service has more commentary.
 * @param listContents
 * @param parentFont
 * @param flavor
 * @returns
 */
function interpretListForTextPath(listContents: ListContent[], parentFont: FontInformation, flavor: FontFlavor): InterpretedTextContent[] {
  const interpretedContent: InterpretedTextContent[] = [];

  for (let i = 0; i < listContents.length; i++) {
    const listItem: ListContent = listContents[i];
    const childFontInfo = createChildFontInformation(listItem, parentFont);

    for (let j = 0; j < listItem.content.length; j++) {
      const listItemContent: TextFieldContent[] = splitListContentIntoLines(listItem.content[j]);

      for (const inlineContent of listItemContent) {
        inlineContent.type = "inline";
        const textBlockContent: string = inlineContent.content;
        const fontFamily = inlineContent.fontFamily ?? childFontInfo.family;
        const fontStyle = inlineContent.fontStyle ?? childFontInfo.style;
        const fontSize = inlineContent.fontSize ?? childFontInfo.size;
        const color = inlineContent.color ?? childFontInfo.color;
        const stroke = inlineContent.stroke ?? childFontInfo.stroke;
        const decorations = inlineContent.decorations ?? childFontInfo.decorations;

        if (!fontFamily || !fontStyle || !fontSize || !color) {
          throw Error("Cannot determine list font style and color");
        }

        const textField: TextField = {
          content: applyCasing(textBlockContent, listItem.casing),
          fontFamily,
          fontStyle,
          fontSize,
          color,
          stroke,
          decorations,
        };

        const textBlock = buildTextBlockRequest(textField, flavor, true);

        interpretedContent.push({
          listOrdinal: false,
          isNewParagraphTextBlock: false,
          textField,
          textBlock,
        });
      }
    }

    if (i !== listContents.length - 1) {
      const lastContent: InterpretedTextContent = interpretedContent[interpretedContent.length - 1];

      lastContent.textField.content += " ";
      lastContent.textBlock.content += " ";
    }
  }

  return interpretedContent;
}

function splitListContentIntoLines(textFieldContent: TextFieldContent): TextFieldContent[] {
  const result: TextFieldContent[] = [];
  const splitContent = textFieldContent.content.split(/\r\n|\r|\n/);

  for (let i = 0; i < splitContent.length; i++) {
    const clonedContent: TextFieldContent = cloneDeep(textFieldContent);
    clonedContent.content = splitContent[i];

    if (i < splitContent.length - 1) {
      clonedContent.content += " ";
    }

    result.push(clonedContent);
  }

  return result;
}
