import { DesignSurface, MirrorDirection, TapestryTileDefinition } from "@mcp-artwork/cimdoc-types-v2";
import { TileAugmenter } from "./TileAugmenter";

export class TapestryAugmenter extends TileAugmenter {
  private tileDirection: MirrorDirection;
  private tileWidth: number;
  private tileHeight: number;

  constructor(tile: TapestryTileDefinition, definitionPanel: DesignSurface) {
    super(tile, definitionPanel);

    this.tileDirection = tile.direction;
    this.tileWidth = this.defpanelWidth * 2;
    this.tileHeight = this.defpanelHeight * 2;
  }

  /// Tapestry Tile will be created as follows
  /// |------pattern-1-mirror------|------pattern-1------------|
  /// |------pattern-1-------------|------pattern-1-mirror------|
  protected override augmentTilePatterns() {
    const patternWidth = this.defpanelWidth;
    const patternHeight = this.defpanelHeight;

    const pattern1Position = this.createBounds(0, 0, patternWidth, patternHeight);
    this.augmentPattern(pattern1Position, 1, this.tileDirection);

    const pattern2Position = this.createBounds(patternWidth, 0, patternWidth, patternHeight);
    this.augmentPattern(pattern2Position);

    const pattern3Position = this.createBounds(0, patternHeight, patternWidth, patternHeight);
    this.augmentPattern(pattern3Position);

    const pattern4Position = this.createBounds(patternWidth, patternHeight, patternWidth, patternHeight);
    this.augmentPattern(pattern4Position, 1, this.tileDirection);
  }

  protected override getTileWidth(): number {
    return this.tileWidth;
  }

  protected override getTileHeight(): number {
    return this.tileHeight;
  }
}
