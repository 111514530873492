import { Point } from "../../math/geometry";

export class HexagonCalculator {
  private readonly centerPoints: Point[];
  private readonly side: number;
  private readonly width: number;
  private readonly height: number;

  // regular hexagon
  private static readonly SquareRoot3 = Math.sqrt(3);

  constructor(side: number, offset: number) {
    this.side = side;
    this.width = this.getHexWidth(side);
    this.height = this.getHexHeight(side);

    const xOffset = 0.75 * this.width + offset;
    const yOffset = this.height / 2 + offset / 2;
    const center: Point = { x: xOffset, y: yOffset };

    this.centerPoints = [];
    this.centerPoints[0] = center; //center Hexagon
    this.centerPoints[1] = { x: center.x - xOffset, y: center.y - yOffset }; // top L 1/4 Hexagon
    this.centerPoints[2] = { x: center.x - xOffset, y: center.y + yOffset }; // bottom L 1/4 Hexagon
    this.centerPoints[3] = { x: center.x + xOffset, y: center.y - yOffset }; // top R 1/4 Hexagon
    this.centerPoints[4] = { x: center.x + xOffset, y: center.y + yOffset }; // bottom R 1/4 Hexagon
  }

  private getHexHeight(side: number): number {
    // h = 2 a cos30 = sqrt(3) a
    return side * HexagonCalculator.SquareRoot3;
  }

  private getHexWidth(side: number): number {
    return 2 * side;
  }

  getCenterPoints(): Point[] {
    return this.centerPoints;
  }
}
