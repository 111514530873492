import { CimpressDocument, DesignDocumentWithPanels, DesignDocumentWithSurfaces, DesignSurface } from "@mcp-artwork/cimdoc-types-v2";

export type ValidationStatus = "pass" | "fail";

export type ValidationResult = {
  status: ValidationStatus;
  error?: string;
};

const hasTextElements = (document: CimpressDocument): boolean => {
  const surfaces = (document.document as DesignDocumentWithSurfaces).surfaces;
  const panels = (document.document as DesignDocumentWithPanels).panels;

  return (
    (surfaces?.some((surface) => surface.simpleTextFields?.length) ||
      panels?.some((panel) => panel.simpleTextFields?.length) ||
      surfaces?.some((surface) => surface.textAreas?.length) ||
      panels?.some((panel) => panel.textAreas?.length)) ??
    false
  );
};

export function validateDocument({ document }: { document: CimpressDocument }): ValidationResult {
  if (!(document.document as DesignDocumentWithSurfaces).surfaces?.length && !(document.document as DesignDocumentWithPanels).panels?.length) {
    return { status: "fail", error: "Input document must contain at least one surface or panel!" };
  }

  if (document.fontRepositoryUrl === undefined && hasTextElements(document)) {
    return { status: "fail", error: "A font repository must be defined!" };
  }

  if (document.sku !== undefined) {
    return { status: "fail", error: "Skus are not supported!" };
  }

  return { status: "pass" };
}

export function validateSurface({ surface }: { surface: DesignSurface }): ValidationResult {
  if (surface.clips?.length) {
    return { status: "fail", error: "Clips are not supported!" };
  }

  if (surface.simpleTextFields?.length) {
    return { status: "fail", error: "Simple text fields are not supported!" };
  }

  return { status: "pass" };
}
