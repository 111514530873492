import { CimpressDocument, DesignSurface, Image, ItemReference, Shape, Subpanel, TextArea, TypedDesignElement, Video } from "@mcp-artwork/cimdoc-types-v2";
import { parseMM } from "./utils/unitHelper";
import { selectItemFromSurface } from "./selectors/selectItemFromSurface";
import { LayoutElement, LayoutInput, LayoutResult, OrderableItemInfo, OrderableLayoutElement, PreviewType, Selector } from "./models/Layout";
import { validateDocument, validateSurface, ValidationResult } from "./layout/document/Validation";
import { BoundingBox } from "./utils/boundingBox";
import { DecorationTechnology, parseTechnology } from "./layout/helpers/Technology";
import { timestamp } from "./utils/time";
import { log } from "./utils/log";
import { layoutItem } from "./layout/layoutItem";
import { validateItem } from "./layout/validateItem";
import { getFilters } from "./utils/paint/colorMode";
import { fallbackItem } from "./fallback/Item";
import { selectSurface } from "./selectors/selectSurface";
import { sanitizeOverprint } from "./utils/paint/Color";
import CimDocDefinitionTreeNode from "./utils/CimDocDefinitionTreeNode";
// import { AudioLayout } from "./layout/Models";
// import { getAudio } from "./layout/audio/Layout";

function selectorToString(selector: Selector): string {
  if (selector.type === "page") {
    return `page ${selector.number}`;
  }
  return `${selector.type} ${selector.id}`;
}

export async function layout(input: LayoutInput): Promise<LayoutResult> {
  const start = timestamp(input.debugOptions?.timers ?? false);
  const { selector, document } = input;

  log({ message: `calling layout function for: ${selectorToString(selector)}`, enabled: input.debugOptions?.log ?? false, objects: input });

  const surface = await selectSurface({ selector, document });

  const documentValidation = validateDocument({ document });
  const surfaceValidation = validateSurface({ surface });

  if (documentValidation.status === "fail") {
    throw new Error(`documentValidation failed ${documentValidation.error}`);
  }

  if (surfaceValidation.status === "fail") {
    throw new Error(`surfaceValidation failed ${surfaceValidation.error}`);
  }

  log({ message: `surface created for: ${selectorToString(selector)}`, enabled: input.debugOptions?.log ?? false, objects: surface });

  // TODO: rename previewtype "document" to "panel"?
  const previewType: PreviewType = selector.type === "item" ? "item" : "document";

  const overprints: string[] = input.overprints?.map((o) => sanitizeOverprint(o) ?? "n/a") ?? [];

  // Reusable function to use for subpanels
  const validateAndLayout = createValidateAndLayout({ ...input, overprints, surface, previewType });

  // let audioPromise: Promise<AudioLayout | undefined>;
  // if (input.videoOptions?.enableVideo && input.videoOptions?.mode === "video") {
  //   audioPromise = getAudio({ surface });
  // } else {
  //   audioPromise = Promise.resolve(undefined);
  // }

  const elementsPromise = validateAndLayout({ surfaceOrSubpanel: surface, selectorFromInput: selector });

  return {
    layoutType: previewType,
    boundingBox: {
      left: 0,
      top: 0,
      width: parseMM(surface.width),
      height: parseMM(surface.height),
    },
    elements: await elementsPromise,
    // audio: await audioPromise,
    filters: getFilters(surface),
    ...(start ? { debugInfo: { timers: { total: timestamp(true) - start } } } : {}),
  };
}

type ValidateAndLayoutArguments = {
  surfaceOrSubpanel: DesignSurface | Subpanel;
  selectorFromInput?: Selector;
  definitionTreeNodeOverride?: CimDocDefinitionTreeNode;
  previewTypeOverride?: PreviewType;
};
type ValidateAndLayoutReturn = Promise<LayoutElement[]>;

// Binds a validateAndLayout function to the given arguments
// validateAndLayout can call itself to layout the items within subpanels
function createValidateAndLayout(
  input: LayoutInput & { surface: DesignSurface; previewType: PreviewType },
): (args: ValidateAndLayoutArguments) => ValidateAndLayoutReturn {
  const {
    document,
    pixelSize,
    debugOptions,
    experimentalOptions,
    forceFallback,
    overprints,
    textOptions,
    imageOptions,
    surface,
    videoOptions,
    referrer,
    previewType,
  } = input;
  const decoTech: DecorationTechnology = parseTechnology((surface as DesignSurface).decorationTechnology);
  const parentBounds: BoundingBox = { left: 0, top: 0, width: parseMM(surface.width), height: parseMM(surface.height) };
  const { colorPalette, definitions } = document.document;
  const definitionTreeNode: CimDocDefinitionTreeNode = new CimDocDefinitionTreeNode(definitions, undefined);
  const enableLog = debugOptions?.log ?? false;
  const fontRepositoryUrl = document.fontRepositoryUrl;
  const trackTime = debugOptions?.timers ?? false;

  const validateAndLayout = async ({
    surfaceOrSubpanel,
    selectorFromInput,
    definitionTreeNodeOverride,
    previewTypeOverride,
  }: ValidateAndLayoutArguments): ValidateAndLayoutReturn => {
    // Grouping all necessary data for easier usage
    const itemInfos: OrderableItemInfo[] =
      selectorFromInput?.type === "item"
        ? [{ depth: 0, ...selectItemFromSurface({ id: selectorFromInput.id, surface: surfaceOrSubpanel }) }]
        : getAllOrderableItemInfo({ surface: surfaceOrSubpanel });

    // Validate all items
    const itemValidators: { itemInfo: OrderableItemInfo; validation: ValidationResult }[] = itemInfos.map((itemInfo) => {
      return { itemInfo, validation: validateItem({ itemInfo, decoTech, experimentalOptions, textOptions }) };
    });

    // Map all items to layoutElements using client side rendering or falling back to server
    return (
      await Promise.all(
        itemValidators.map(async ({ itemInfo, validation }) => {
          let error = forceFallback ? "forced fallback" : validation.error;
          if (!forceFallback && validation.status === "pass") {
            try {
              // Client side layout
              // await to make try catch work
              return await layoutItem({
                decoTech,
                enableLog,
                fontRepositoryUrl,
                itemInfo,
                parentBounds,
                trackTime,
                pixelSize,
                textOptions,
                imageOptions,
                validateAndLayout, // used for subpanels
                experimentalOptions,
                videoOptions,
                overprints,
                definitionTreeNode: definitionTreeNodeOverride ?? definitionTreeNode,
                referrer,
                previewType: previewTypeOverride ?? previewType,
                colorPalette,
              });
            } catch (e) {
              error = (e as Error)?.message ?? e;
            }
          }

          // Don't fallback to serverside for ornament
          if (itemInfo.itemType === "ornament") {
            throw new Error(error);
          }

          // Server side layout (fetching image)
          return await fallbackItem({
            decoTech,
            enableLog,
            fontRepositoryUrl,
            itemInfo,
            parentBounds,
            pixelSize,
            trackTime,
            error,
            overprints,
            previewType: previewTypeOverride ?? previewType,
            definitionTreeNode: definitionTreeNodeOverride ?? definitionTreeNode,
            referrer,
            colorPalette,
          });
        }),
      )
    )
      .filter((x): x is OrderableLayoutElement => {
        // filter undefined, like skipped ornaments
        return !!x;
      })
      .sort((a, b) => a.depth - b.depth)
      .map<LayoutElement>((orderedElement) => orderedElement.value);
  };

  const cimdoc = document;

  setTimeout(() => {
    console.log({ cimdoc });
  }, 1000);

  return validateAndLayout;
}

function getItemInfo(designElement: TypedDesignElement, depth: number): OrderableItemInfo {
  switch (designElement.itemType) {
    case "shape":
      return { itemType: "shape", item: designElement as Shape, depth };
    case "image":
      return { itemType: "image", item: designElement as Image, depth };
    case "textArea":
      return { itemType: "textArea", item: designElement as TextArea, depth };
    case "itemReference":
      return { itemType: "itemReference", item: designElement as ItemReference, depth };
    case "subpanel":
      return { itemType: "subpanel", item: designElement as Subpanel, depth };
    case "video":
      return { itemType: "video", item: designElement as Video, depth };
    default:
      throw `Unknown itemType`;
  }
}

// Grouping all necessary data for easier usage
function getAllOrderableItemInfo({ surface }: { surface: DesignSurface | Subpanel }): OrderableItemInfo[] {
  const basicItems = [
    ...(surface.images?.map<OrderableItemInfo>((image) => {
      return { depth: image.zIndex ?? 0, item: image, itemType: "image" };
    }) || []),
    ...(surface.shapes?.map<OrderableItemInfo>((shape) => {
      return { depth: shape.zIndex ?? 0, item: shape, itemType: "shape" };
    }) || []),
    ...(surface.textAreas?.map<OrderableItemInfo>((textArea) => {
      return { depth: textArea.zIndex ?? 0, item: textArea, itemType: "textArea" };
    }) || []),
    ...(surface.itemReferences?.map<OrderableItemInfo>((itemRef) => {
      return { depth: itemRef.zIndex ?? 0, item: itemRef, itemType: "itemReference" };
    }) || []),
    ...(surface.subpanels?.map<OrderableItemInfo>((subpanel) => {
      return { depth: subpanel.zIndex ?? 0, item: subpanel, itemType: "subpanel" };
    }) || []),
    ...(surface.videos?.map<OrderableItemInfo>((video) => {
      return { depth: video.zIndex ?? 0, item: video, itemType: "video" };
    }) || []),
  ];

  if (surface.background || surface.foreground) {
    const depthValues = basicItems.map((item) => item.depth);
    const minDepth = depthValues.length === 0 ? 0 : Math.min(...depthValues);
    const maxDepth = depthValues.length === 0 ? 0 : Math.max(...depthValues);
    if (surface.background?.items) {
      let offset = -1;
      for (let i = surface.background.items.length - 1; i >= 0; i--) {
        const itemInfo = getItemInfo(surface.background.items[i], minDepth + offset);
        basicItems.push(itemInfo);
        offset--;
      }
    }

    if (surface.foreground?.items) {
      let offset = 1;
      for (let i = 0; i < surface.foreground.items.length; i++) {
        const itemInfo = getItemInfo(surface.foreground.items[i], maxDepth + offset);
        basicItems.push(itemInfo);
        offset++;
      }
    }
  }

  if (surface.ornaments) {
    // add ornaments last
    surface.ornaments.forEach((ornament) => {
      basicItems.push({
        depth: Infinity,
        item: ornament,
        itemType: "ornament",
      });
    });
  }

  return basicItems;
}
