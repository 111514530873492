import { DesignSurface, Subpanel, Image, Shape, Position, TextArea, TileDefinition, MirrorDirection, ItemClip } from "@mcp-artwork/cimdoc-types-v2";
import { RectangularSvgPathCreator, SvgPathCreator } from "./SvgPathCreator";
import { BoundingBox } from "../../boundingBox";
import { parseMM } from "../../unitHelper";
import { Matrix } from "../../math/matrix";
import { cloneDeep } from "lodash";

export abstract class TileAugmenter {
  private readonly BORDER_COLOR = "rgb(255,255,255)";
  private readonly DEFAULT_UNIT = "mm";

  // make it the highest number possible
  private readonly BORDER_ZINDEX = 200;

  // make it the lowest number possible
  protected readonly BACKGROUND_ZINDEX = -999;
  protected readonly NamePrefix = "tile-augmenter";

  protected tile: TileDefinition;
  protected elements: TilePatternElements;

  protected defpanelWidth: number;
  protected defpanelHeight: number;
  protected definitionPanel: DesignSurface;

  constructor(tile: TileDefinition, definitionPanel: DesignSurface) {
    this.tile = tile;
    this.definitionPanel = definitionPanel;
    this.defpanelWidth = parseMM(definitionPanel.width);
    this.defpanelHeight = parseMM(definitionPanel.height);
    this.elements = new TilePatternElements();
  }

  public augmentUniformTile(): DesignSurface {
    try {
      const convertedPanel: DesignSurface = {
        id: `${this.NamePrefix}-panel`,
        name: `${this.NamePrefix}-panel`,
        width: this.withUnit(this.getTileWidth(), this.DEFAULT_UNIT),
        height: this.withUnit(this.getTileHeight(), this.DEFAULT_UNIT),
        subpanels: [this.createTileSubpanel()],
        decorationTechnology: this.definitionPanel.decorationTechnology,
      };

      return convertedPanel;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  private createTileSubpanel(): Subpanel {
    this.augmentTilePatterns();
    this.addTileBorder();
    this.addTileBackground();
    return this.createSubpanel();
  }

  protected abstract getTileWidth(): number;

  protected abstract getTileHeight(): number;

  protected abstract augmentTilePatterns();

  protected augmentPattern(patternBound: BoundingBox, scale = 1, direction?: MirrorDirection, pathCreator?: SvgPathCreator) {
    if (this.definitionPanel.images) {
      //Revisit - layout in linear fashion for the clips to work properly
      this.definitionPanel.images.forEach((image) => {
        const patternImage = this.createImagePatternAndUpdateBound(patternBound, image, scale, direction, pathCreator);

        this.elements.addImage(patternImage);
      });
    }
  }

  private createImagePatternAndUpdateBound(
    patternBound: BoundingBox,
    image: Image,
    scale: number,
    direction?: MirrorDirection,
    pathCreator?: SvgPathCreator,
  ): Image | undefined {
    const patternImage = cloneDeep(image);
    if (null !== patternImage && this.isValidPosition(image.position)) {
      const imageHeight = parseMM(image.position.height) * scale;
      const imageWidth = parseMM(image.position.width) * scale;

      // centering image is not ideal when there can be multi elements in the panel
      // fit the best way aligned to the left top
      const positionTransform = Matrix.translate(patternBound.left, patternBound.top);
      const imagePoint = positionTransform.toDOMMatrix().transformPoint({ x: parseMM(image.position.x), y: parseMM(image.position.y) });

      const patternPosition = this.createPosition(imagePoint.x, imagePoint.y, imageWidth, imageHeight);
      patternImage.position = patternPosition;

      const clipX = imagePoint.x;
      const clipY = imagePoint.y;
      const clipWidth = patternBound.width > imageWidth ? imageWidth : patternBound.width;
      const clipHeight = patternBound.height > imageHeight ? imageHeight : patternBound.height;

      // clipping to crop without affecting the aspect ratio
      const clipBound: BoundingBox = {
        left: clipX,
        top: clipY,
        width: clipWidth,
        height: clipHeight,
      };
      const svgCreator = pathCreator ?? new RectangularSvgPathCreator(clipBound);
      patternImage.clipping = this.createItemClip(svgCreator, patternPosition);

      if (null !== direction && undefined !== direction) {
        patternImage.mirrorDirection = direction;
      }

      return patternImage;
    }
  }

  private isValidPosition(position: Position): boolean {
    return this.isValidString(position.x) && this.isValidString(position.y) && this.isValidString(position.width) && this.isValidString(position.height);
  }

  private isValidString(dimension: string): boolean {
    return null !== dimension && undefined !== dimension;
  }

  private createItemClip(pathCreator: SvgPathCreator, position: Position): ItemClip {
    const itemClip: ItemClip = {
      specification: {
        type: "svgPathData",
        origin: "panel",
        data: pathCreator.createSvgPath(),
      },

      viewBox: `${parseMM(position.x)} ${parseMM(position.y)} ${parseMM(position.width)} ${parseMM(position.height)}`,
    };
    return itemClip;
  }

  private addTileBorder() {
    if (this.tile.border) {
      const border: Shape = {
        id: `${this.NamePrefix}-tileborder-shape`,
        type: "rectangle",
        zIndex: this.BORDER_ZINDEX,
        position: {
          x: "0mm",
          y: "0mm",
          width: this.withUnit(this.getTileWidth(), this.DEFAULT_UNIT),
          height: this.withUnit(this.getTileHeight(), this.DEFAULT_UNIT),
        },
        stroke: {
          color: this.tile.borderColor ?? this.BORDER_COLOR,
          thickness: this.tile.border,
        },
      };
      this.elements.addShape(border);
    }
  }

  private addTileBackground() {
    if (this.tile.backgroundColor) {
      const background: Shape = {
        id: `${this.NamePrefix}-tilebackground-shape`,
        type: "rectangle",
        zIndex: this.BACKGROUND_ZINDEX,
        position: this.createPosition(0, 0, this.getTileWidth(), this.getTileHeight()),
        color: this.tile.backgroundColor,
      };
      this.elements.addShape(background);
    }
  }

  private createSubpanel(): Subpanel {
    const subpanel: Subpanel = {
      id: `${this.NamePrefix}-subpanel`,
      position: {
        x: "0mm",
        y: "0mm",
      },
      images: this.elements.images,
      textAreas: this.elements.textAreas,
      shapes: this.elements.shapes,
    };

    return subpanel;
  }

  protected createBounds(x: number, y: number, width: number, height: number): BoundingBox {
    const box = {
      left: x,
      top: y,
      width: width,
      height: height,
    };

    return box;
  }

  protected convertToBounds(position: Position): BoundingBox {
    const box = {
      left: parseMM(position.x),
      top: parseMM(position.y),
      width: parseMM(position.width),
      height: parseMM(position.height),
    };

    return box;
  }

  protected createPosition(x: number, y: number, width: number, height: number): Position {
    const position: Position = {
      x: this.withUnit(x, this.DEFAULT_UNIT),
      y: this.withUnit(y, this.DEFAULT_UNIT),
      width: this.withUnit(width, this.DEFAULT_UNIT),
      height: this.withUnit(height, this.DEFAULT_UNIT),
    };

    return position;
  }

  private withUnit(quantity: number, unit: string): string {
    return `${quantity}${unit}`;
  }
}

class TilePatternElements {
  images: Array<Image>;
  shapes: Array<Shape>;
  textAreas: Array<TextArea>;

  constructor() {
    this.images = new Array<Image>();
    this.shapes = new Array<Shape>();
    this.textAreas = new Array<TextArea>();
  }

  addImage(image: Image | undefined) {
    if (image) {
      this.images.push(image);
    }
  }

  addShape(shape: Shape | undefined) {
    if (shape) {
      this.shapes.push(shape);
    }
  }

  addTextArea(textArea: TextArea | undefined) {
    if (textArea) {
      this.textAreas.push(textArea);
    }
  }
}
