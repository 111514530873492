import { createContext } from "react";
import { GlobalSettingsOptions } from "../GlobalSettings";

export const defaultGlobalOptions: GlobalSettingsOptions = {
  log: false,
  textOptions: {
    rtextEnabled: true,
    requestTextBoundaries: ["word"],
    rtextResult: true,
  },
  videoEnabled: false,
  timers: false,
  experimentalOptions: { svgInImagesSupport: true },
};

export const GlobalOptionsContext = createContext<{ options: GlobalSettingsOptions; setOptions: (options: GlobalSettingsOptions) => void }>({
  options: defaultGlobalOptions,
  setOptions: (options: GlobalSettingsOptions) => undefined,
});
